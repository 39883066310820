
/** Generated by FG **/
@font-face {
	font-family: 'Conv_VICECITYSANS';
	src: url('fonts/VICECITYSANS.eot');
	src: local('☺'), url('fonts/VICECITYSANS.ttf') format('truetype'), url('fonts/VICECITYSANS.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


*{
  font-family: 'Conv_VICECITYSANS'
}

body.works-page #header {
  position: relative;
}


#header {
    top: 0;
    margin-top: 50px;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.logowrap a {
    position: absolute;
    display: block;
}
a.navbar-logo {
    z-index: 1;
}
.logowrap {
    width: 112px;
}

ul#main-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}
li.nav-item {
    flex-flow: row;
    padding: 15px 40px;
    width: 185px;
}
.navbar-logo, .navbar-logo-white{
    cursor: pointer;
}

div#main-menu-wrapper {
    overflow: hidden;
    position: relative;
}
ul#main-menu.menu-in {
    z-index: 9999;
    position: relative;
    left: 0;
    transition: all 1s ease-in-out;
    -webkit-animation: slide-menu-out 1s forwards;
    -webkit-animation-delay: 0;
    animation: slide-menu-out 1s forwards;
    animation-delay: 0;
}

ul#main-menu.menu-out{
    z-index: 9999;
    position: relative;
    transition: all 1s ease-in-out;
    -webkit-animation: slide-menu 1s forwards;
    -webkit-animation-delay: 0;
    animation: slide-menu 1s forwards;
    animation-delay: 0;
 
}
a.nav-links {
    font-size: 16px;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

li.nav-item.last-item {
    padding: 15px 20px;
}

.last-item a.nav-links {
    background: #bf1906;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
}

.parallax-container {
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
}

.home-left h1 {
    font-size: 60px;
}

.home-left p {
    width: 60%;
    text-align: justify;
    margin-top: 5%;
}

.button-rs {
    background: #bf1906;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 20px;
    display: inline-block;
}

.home-left-wrapper {
    height: 100vh;
    padding-top: 20%;
}
.home-right-wrapper {
    position: relative;
    height: 100vh;
}

.arrow {
    opacity: 0;
}
.arrow-slide-out{
    -webkit-animation: slide_out_arrow 0.5s forwards;
    -webkit-animation-delay: 0;
    animation: slide_out_arrow 0.5s forwards;
    animation-delay: 0;
}
.arrow-slide {
    position: absolute;
    left: -200vw;
    bottom: 0;
    -webkit-animation: slide 1s forwards;
    -webkit-animation-delay: 0;
    animation: slide 1s forwards;
    animation-delay: 0;
    opacity: 1;
}

.arrow-slide-2{
    position: absolute;
    left: -200vw;
    bottom: 25%;
    -webkit-animation: slide-2 0.5s forwards;
    -webkit-animation-delay: 0;
    animation: slide-2 0.5s forwards;
    animation-delay: 0;
    opacity: 1;
}
.arrow-slide-3{
    position: absolute;
    left: -200vw;
    bottom: 45%;
    -webkit-animation: slide-3 0.6s forwards;
    -webkit-animation-delay: 0;
    animation: slide-3 0.6s forwards;
    animation-delay: 0;
    opacity: 1;
}

.arrow-slide-4{
    position: absolute;
    left: -200vw;
    bottom: 55%;
    -webkit-animation: slide-4 0.8s forwards;
    -webkit-animation-delay: 0;
    animation: slide-4 0.8s forwards;
    animation-delay: 0;
    opacity: 1;
}

img.services-left {
    width: 40%;
    display: block;
    position: absolute;
    height: 100vh;
}

.services-right-wrapper {
    height: 100vh;
    position: relative;
    padding-left: 10%;
    padding-top: 15%;
}

.services-left-wrapper {
    position: relative;
    text-align: right;
    color: white;
    height: 100vh;
    padding-top: 25%;

}
.services-left h1 {
    font-size: 60px;
}

.services-left h3 {
    font-size: 40px;
}

.services-left p {
    width: 70%;
    float: right;
    margin-top: 10%;
}

.services-left {
    padding-right: 20px;
}

.services-right-wrapper img {
    height: 100px;
    margin-bottom: 20px;
}

.services-right-wrapper h5 {
    color: #bf1906;
    margin-bottom: 20px;
}

.services-right-wrapper p {
    width: 65%;
    margin-bottom: 40px;
}

.team-left-wrapper {
    position: relative;
    text-align: right;
    color: black;
    height: 100vh;
    padding-top: 25%;

}

.team-left h1 {
    font-size: 60px;
    opacity: 0;
}

.team-left h3 {
    font-size: 40px;
}

.team-left p {
    font-size: 20px;
    float: right;
    margin-top: 10%;
}

.team-right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: masonry;
}


.team-right-wrapper {
    padding-top: 15%;
    padding-left: 5%;
}

.team-rights {
    display: flex;
    padding-left: 10%;
}

.team-vector {
    padding: 5px;
}

.teamvector1{
  opacity: 0;
}

.works-left-wrapper {
    position: relative;
    text-align: right;
    color: black;
    height: 100vh;
    padding-top: 25%;

}

.works-left h1 {
    font-size: 60px;
    opacity: 0;
}

.works-left h3 {
    font-size: 40px;
}

.works-left p {
    font-size: 20px;
    float: right;
    margin-top: 10%;
}

.works-right-wrapper {
    padding-top: 25%;
    padding-left: 5%;
    position: relative;
}

img.works-right-img {
    width: 450px;
    position: absolute;
    opacity: 0;
}

img#mobile-phones {
    bottom: 20%;
    right: 12%;
}

img#monitor {
    left: 15%;
    top: 22%;
}

.works-rights {
    display: flex;
    padding-left: 10%;
}

.white-btn.button-wrapper .button-rs {
    background: white;
    border: #bf1906 solid 3px;
    color: #bf1906;
}

div#contact, #mobile-contact {
    background: #c01905;
}

.contact-wrapper {
    padding-top: 20%;
}

.contact-title {
    font-size: 110px;
    color: white;
    margin-bottom: 40px;

}

.contact-details-wrapper {
    width: 500px;
    display: inline-block;
    vertical-align: top;
}

.contact-item {
    color: white;
    font-size: 30px;
    border-bottom: solid 3px #ffffff66;
    vertical-align: top;
    padding: 15px;
    line-height: 35px;
}
.phone-number {
    width: 80px;
}
.contact-item * {
    float: left;
}

.email-address {
    width: 80px;
}

.navbar-logo img{
    transition-property: opacity;
    transition-duration: 1s;
}

#services img.services-left{
    position: absolute;
    left: -200vw;
    transition: all .75s ease-out;
    -webkit-animation: slide_out 1s backwards;
    -webkit-animation-delay: 0;
    animation: slide_out 1s backwards;
    animation-delay: 0;
}
#services.active img.services-left{
    transition: all .75s ease-out;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0;
    animation: slide 0.5s forwards;
    animation-delay: 0;
}


/**
* LATEST WORKS
*/

.latest-works-wrapper {
  /*height: 100vh;*/
  padding-top: 10%;
}

.latest-works-wrapper-2 {
  /*height: 100vh;*/
  padding-top: 5%;
}
.latest-works-right-wrapper {
  position: relative;
  height: 100vh;
}

.works-large-title {
  font-size: 60px;
}

.works-descriptions {
  font-size: 24px;
  margin-top: 25px;
}

img#latest-works-arrow {
  margin: 20% 20%;
}

.latest-works-wrapper-2 li.nav-item {
  flex-flow: row;
  padding: 0px 40px 0 0;
  width: auto !important;
}
.latest-works-wrapper-2 .nav-link {
  color: #22262a;
}

.latest-works-wrapper-2 .nav-tabs .nav-link.active {
  color: #bf0909;
}

.dev-tools-wrapper {
  display: flex;
  
}
.dev-tool {
  flex: auto;
  padding: 15px;
 
}
.dev-tool img {
  width: 100%;
  filter: grayscale(1);
}

img.latest-work-logo:hover {
  filter: grayscale(0);
}


.latest-works-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 46px;
}

.ecommerce-wrapper {
  display: flex;
}


.ecommerce {
  flex: auto;
  padding: 15px;
 
}


.ecommerce-wrapper a {
  text-decoration: none !important;
}

.ecommerce-title h3 {
  text-decoration: unset !important;
  color: #212529;
  margin-top: 20px;
}
/*
ul#main-menu{
    position: absolute;
    left: -200vw;
    -webkit-animation: slide_out 0.8s;
    -webkit-animation-delay: 0;
    animation: slide_out 0.8s;
    animation-delay: 0; 
}
ul#main-menu.active{
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0;
    animation: slide 0.5s forwards;
    animation-delay: 0;
}


header#header .container {
    position: relative;
    overflow: hidden;
}

*/

@-webkit-keyframes slide {
    100% { left: 0; }
}

@keyframes slide {
    100% { left: 0; }
}

@-webkit-keyframes slide-2 {
    100% { left: 20%; }
}

@keyframes slide-2 {
    100% { left: 20%; }
}


@-webkit-keyframes slide-3 {
    100% { left: 75%; }
}

@keyframes slide-3 {
    100% { left: 75%; }
}


@-webkit-keyframes slide-4 {
    100% { left: 60%; }
}

@keyframes slide-4 {
    100% { left: 60%; }
}


@-webkit-keyframes slide_out {
    100% { left: -200vw; }
}

@keyframes slide_out {
    100% { left: -200vw; }
}

@-webkit-keyframes slide-menu {
    100% { left: 100%; }
}

@keyframes slide-menu {
    100% { left: 100%; }
}

@-webkit-keyframes slide-menu-out {
    100% { left: 0%; }
}

@keyframes slide-menu-out {
    100% { left: 0%; }
}


@-webkit-keyframes slide_out_arrow {
    100% { left: -200vw; }
}

@keyframes slide_out_arrow {
    100% { left: -200vw; }
}


.fadeOut{
    opacity:0;
    width:0;
    height:0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.fadeIn{
    opacity:1;
    width:100px;
    height:100px;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}


.tracking-in-contract-bck {
	-webkit-animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-contract-bck {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(400px);
              transform: translateZ(400px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes tracking-in-contract-bck {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(400px);
              transform: translateZ(400px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	animation: swing-in-top-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.swing-in-left-fwd {
	-webkit-animation: swing-in-left-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-left-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}


.swing-in-top-bck {
	-webkit-animation: swing-in-top-bck 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-bck 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.swing-in-right-fwd {
	-webkit-animation: swing-in-right-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-right-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.swing-in-bottom-fwd {
	-webkit-animation: swing-in-bottom-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-bottom-fwd 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.focus-in-contract {
	-webkit-animation: focus-in-contract 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-contract 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }

}

@-webkit-keyframes swing-in-left-fwd {
    0% {
      -webkit-transform: rotateY(100deg);
              transform: rotateY(100deg);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 1;
    }
  }
  @keyframes swing-in-left-fwd {
    0% {
      -webkit-transform: rotateY(100deg);
              transform: rotateY(100deg);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 1;
    }
  }
  

  @-webkit-keyframes swing-in-top-bck {
    0% {
      -webkit-transform: rotateX(70deg);
              transform: rotateX(70deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-bck {
    0% {
      -webkit-transform: rotateX(70deg);
              transform: rotateX(70deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }

  @-webkit-keyframes swing-in-right-fwd {
    0% {
      -webkit-transform: rotateY(-100deg);
              transform: rotateY(-100deg);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 1;
    }
  }
  @keyframes swing-in-right-fwd {
    0% {
      -webkit-transform: rotateY(-100deg);
              transform: rotateY(-100deg);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
              transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 1;
    }
  }
  @keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
              transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 1;
    }
  }

  @-webkit-keyframes focus-in-contract {
    0% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes focus-in-contract {
    0% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  
  

.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.roll-out-right {
	-webkit-animation: roll-out-right 0.6s ease-in both;
	        animation: roll-out-right 0.6s ease-in both;
}
@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes roll-out-right {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) rotate(540deg);
              transform: translateX(1000px) rotate(540deg);
      opacity: 0;
    }
  }
  @keyframes roll-out-right {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) rotate(540deg);
              transform: translateX(1000px) rotate(540deg);
      opacity: 0;
    }
  }


  @-webkit-keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
  }
  
   

  .tracking-in-contract-bck-bottom {
	-webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.785, 0.135, 0.150, 0.860) both;
	        animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.785, 0.135, 0.150, 0.860) both;
}


.slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}


@-webkit-keyframes tracking-in-contract-bck-bottom {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(400px) translateY(300px);
              transform: translateZ(400px) translateY(300px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes tracking-in-contract-bck-bottom {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(400px) translateY(300px);
              transform: translateZ(400px) translateY(300px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  
  #mobile-header .logowrap {
    margin-top: 25px;
    margin-left: 10px;
}

.bm-menu-wrap {
  top: 0;
}

#mobile-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 90px;
  background: white;
  box-shadow: -1px 5px 20px 6px #cbcbcb;
}
#mobile-home .home-left p {
  width: 100%;
  text-align: center;
  margin-top: 5%;
  padding: 15px;
}

#mobile-home .home-left h1, #mobile-services #services-title, #mobile-team #mobile-team-title, #mobile-works-title {
  font-size: 50px;
  text-align: center;
  margin-top: 30%;
}

.see-more-btn a {
  background: #bf0808;
  color: white;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 25px;
}

.see-more-btn {
  text-align: center;
  margin-top: 35px;
}


.mobile-homepage .services-right-wrapper {
  padding: 10%;
  text-align: center;
  height: auto !important;
}

.mobile-homepage .services-right-wrapper p, #mobile-services p , #mobile-team p , .mobile-works-left p{
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  padding: 15px;
}

div#mobile-services {
  padding-top: 40px;
}

#mobile-team .team-vector {
  text-align: center;
}

.white-btn.button-wrapper {
  text-align: center;
}

img#mobile-monitor {
  max-width: 80%;
  display: block;
  margin: auto;
}

img#mobile-mobile-phones {
  max-width: 80%;
  display: block;
  margin: auto;
}

.white-btn.button-wrapper {
  text-align: center;
  margin-bottom: 10%;
  margin-top: 10%;
}

div#mobile-contact-title {
  font-size: 50px;
  text-align: center;
}

#mobile-contact .contact-details-wrapper {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 15%;
}

#mobile-contact .contact-item {
  color: white;
  font-size: 21px;
  border-bottom: solid 3px #ffffff66;
  vertical-align: top;
  padding: 15px;
  line-height: 35px;
}